import React from "react";
import {
    Button,
    Row,
    Col,
    Modal,
    Badge,
    Image,
} from "react-bootstrap";
import {capitalize} from "../../lib/outilsForms"
import StatusProspect from "../../lib/Constant/Status/Propsect.constant"
import {useHistory} from "react-router-dom";
import StatusSource from "../../lib/Constant/Status/Source.constant"
import { format } from 'date-fns';
import SweetAlert from "react-bootstrap-sweetalert";



export default function ViewProspect({prospect,nameAttribute,nbOffrePropal,nbOffreSaSp}) {
    const [loading, setLoading] = React.useState<Boolean>(true);
    const [ProspectVal, ] = React.useState(["nom", "prenom", "societe", "tel", "email", "localisation", "description"])
    const [Alert,setAlert]=React.useState(null);
    const [show, setShow] = React.useState(false);

    let history = useHistory();

    // pour arrêter l'avertissement d'appeler setState d'un composant non monté
    React.useEffect(() => {
        if(nameAttribute===undefined){
            setLoading(true);
        
        }else{
            setLoading(false);
        }        
     
    }, []);
    const handleToClickAddOffre=()=>{
            if(nbOffrePropal>0){
                setAlert(
                          <SweetAlert
                            info
                            style={{ display: "block", marginTop: "-100px" }}
                            title={(nbOffrePropal===1)?"Une offre existe déjà":"Des offres existe déjà "}
                            onConfirm={() => {history.push(`/admin/add_propale/${prospect._id}`)}}
                            onCancel={() => setAlert(null)}
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Oui"
                            cancelBtnText="Non"
                            showCancel
                          >
                            Voulez-vous créer une nouvelle offre ?
                          </SweetAlert>
                        );
            }else{
                history.push(`/admin/add_propale/${prospect._id}`)
            }
            
    }
    const handleToClickAddSaSp=()=>{
            if(nbOffreSaSp>0){
                setAlert(
                          <SweetAlert
                            info
                            style={{ display: "block", marginTop: "-100px" }}
                            title={(nbOffreSaSp===1)?"Une SA SP existe déjà":"Des SA SP existe déjà "}
                            onConfirm={() => {history.push(`/admin/add_sasp/${prospect._id}`)}}
                            onCancel={() => setAlert(null)}
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Oui"
                            cancelBtnText="Non"
                            showCancel
                          >
                            Voulez-vous en créer une nouvelle ?
                          </SweetAlert>
                        );
            }else{
                history.push(`/admin/add_sasp/${prospect._id}`)
            }
            
    }
    return (
     <>
            {Alert}
            <div className="places-sweet-alerts"></div>
            <Button
                onClick={()=>setShow(true)}
                variant="info"
                className="text-info btn-link like"
            >
                <i className="fa fa-eye" />
            </Button>{" "}
            {(prospect!==null)?
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
            >
            
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12"  style={{textAlign:"center"}} >
                            <h2 style={{marginTop:"0px",marginBottom:"30px",fontWeight:'bold'}} >
                                Carte du prospect
                            </h2>
                        </Col>
                        <Col md="12" style={{textAlign:"center", marginBottom: "20px",float:"left"}}>
                            <Button 
                                variant="primary" 
                                style={{marginRight:"10px"}}
                                onClick={()=>handleToClickAddOffre() } 
                            > 
                                Ajouter une Propal 
                            </Button>
                            <Button 
                                variant="primary" 
                                style={{marginRight:"10px"}}
                                onClick={()=>handleToClickAddSaSp() } 
                            > 
                                Ajouter une SA/SP 
                            </Button>
                            <Button 
                                style={{marginRight:"10px"}}
                                onClick={()=>history.push(`/admin/edit_prospect/${prospect._id}`) } 
                                variant="primary"
                            > 
                                Modifier
                            </Button>
                            
                        </Col>
                        {(prospect['pictureUrl']!==undefined)?
                            <Col md="12" style={{overflow:'unset'}}>
                                <Col md={{span:8,offset:2}} style={{marginBottom:'20px'}}>
                                <Image 
                                    src={`${process.env.REACT_APP_API_BASE_URL}uploads/${prospect['pictureUrl']}`} 
                                    style={{borderRadius:'5px'}}
                                    fluid 
                                />
                                </Col>
                            </Col>
                        :null}
                        <Col md="6" style={{ borderRight: "1px  solid black",textAlign:'center' }}>
                            <Badge variant="secondary" style={{ marginBottom: "20px",padding:'10px' }}>
                                Informations du Prospect
                            </Badge>
                            <Row style={{ marginBottom: "5px"}}>
                                <Col md="4">
                                    <span style={{ float: "left"}} >
                                        Genre:
                                    </span>
                                </Col>
                                <Col md="8" style={{overflow: "auto"}}>
                                    <span   style={{ float: "left", fontWeight: "bold" }} >
                                        {prospect.genre}
                                    </span>
                                </Col>
                            </Row>
                            {ProspectVal.map((current,key) => (
                                <Row key={key} style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span style={{ float: "left"}}>
                                            {capitalize(current)}:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span style={{ float: "left", fontWeight: "bold",textAlign:"initial"}} >
                                            {(current==="tel")?(<a href={`tel:${prospect[current]}`}>{prospect[current]}</a>):
                                            (current==="email")?(<a href={"mailto:".concat(prospect[current])}>{prospect[current]}</a>):
                                            (current==="localisation")?(prospect[current]===null)?"--":(prospect[current].label)
                                            :(prospect[current]===""||prospect[current]===null)?"--":capitalize(prospect[current])}
                                        </span>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        <Col md="6" style={{textAlign:'center'}}>
                            <Badge variant="secondary" style={{ marginBottom: "20px",padding:'10px' }}>
                                Informations Générales
                            </Badge>
                            <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span style={{ float: "left"}}>
                                            Status:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span  style={{ float: "left", fontWeight: "bold" }} >
                                            {StatusProspect.find((o) => o.value === prospect.status).label}
                                        </span>
                                    </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span style={{ float: "left"}} >
                                            Source:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span style={{ float: "left", fontWeight: "bold" }} >
                                            {StatusSource.find((o) => o.value === prospect.source).label}
                                        </span>
                                    </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span style={{ float: "left"}} >
                                             Modifer le:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span style={{ float: "left", fontWeight: "bold" }}>
                                            {format(new Date(prospect.createdAt), 'dd/MM/yyyy kk:mm:ss')}
                                        </span>
                                    </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span  style={{ float: "left"}} >
                                            Attribuer à:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span  style={{ float: "left", fontWeight: "bold" }}>
                                            {(loading)?"undefined":(nameAttribute.prenom+" "+nameAttribute.nom)}
                                        </span>
                                    </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span  style={{ float: "left"}} >
                                        Offres jointes:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span  style={{ float: "left", fontWeight: "bold" }}>
                                            {nbOffrePropal}
                                        </span>
                                    </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>    
            :null
        }
      </>
    )
}
