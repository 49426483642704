import React, { useEffect, useState } from "react";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Container,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";
import Select from "react-select";

import AuthService from "services/Api/Controllers/AuthService";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import AdminService from "services/Api/Controllers/AdminService";
import { Redirect } from "react-router-dom";

function RegisterPageStep2({match}) {

    const [AllSociete, setAllSociete] = useState([]);
    const [loading, setLoading] = useState<Boolean>(true);
    const [IsTrue,setisTrue] = useState(undefined);
    const [AdminGrether, SetAdminGrether] = useState([])
    const [AdminGretherSelected, SetAdminGretherSelected] = useState([])
    const [isSubscribed, SetIisSubscribed] = useState(true);
    const [submitCounter, setsubmitCounter] = useState([]);

    useEffect(() => {
        let isMounted = true;


        async function Loaduser() {
            if (IsTrue===undefined) {
               const valretur= await AuthService.getById(match?.params?.id)
              if (isMounted){
                setisTrue(valretur)
              }
            }
        }
        if (IsTrue===undefined) {Loaduser();}



        async function loadData() {
            if (isSubscribed) {
                await SocieteServices.getSocietesById(IsTrue?.societes)
                .then(res => { if(isMounted)  {setAllSociete(res); SetIisSubscribed(false);}})
            }
        }
        if (IsTrue!==undefined&&IsTrue!=="false"&&isSubscribed) loadData();
      

        async function loadDataAdmin() {
            if (!isSubscribed) {
                const grether_admin = await AuthService.getAllByAdminId(AllSociete.map((item) => item._id));
                if(isMounted)  {
                    SetAdminGrether(grether_admin)
                    setLoading(false)
                }
            }
        }
        if (!isSubscribed) loadDataAdmin();

        return () => {isMounted=false; };
    }, [isSubscribed,IsTrue]);

    var onClickSubmit= ()=>{
         function dataSubmit() {
            AdminGretherSelected.map(item=>{
                   AdminService.saveToDB(match?.params?.id,item.id,item.value)
                   .then(res=>{ if (res===true) setsubmitCounter(oldArray => [...oldArray, true]); })
                });
            }
          async function dataUpdate() {
                await AuthService.UpdateAdmniSelected();
            }
            dataSubmit();
            dataUpdate();
    }
    if(!AuthService.getToken()) return <Redirect to="/auth/register"/>
    
    if(loading===false){
        if(submitCounter.length===AllSociete.length) {
            alert("Votre compte a été créé avec succès");
            return  <Redirect to="/admin/dashboard"/>   
        }
    }
    if(IsTrue==="false"){
            alert("Une erreur est survenue");
            return  <Redirect to="/admin/dashboard"/>   
    }
    return (
            <div
                className="register-page section-image"
                data-color="black"
            >
                <div className="content d-flex align-items-center">
                    <Container>
                        <Card className="card-register card-plain text-center">
                            <Card.Header>
                                <Row className="justify-content-center">
                                    <Col md="8">
                                        <div className="header-text">
                                            <Card.Title as="h2">
                                                Administration
                                            </Card.Title>
                                            <hr></hr>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as="h4">Definir le superieur hiérarchique</Card.Title>
                            </Card.Body>
                            <Card.Footer>
                                {(loading) ? <Spinner animation="border" variant="primary" /> :
                                    AllSociete.map((item,index) => (
                                        <Row key={index}>
                                            <Col md="3"/>
                                            <Col md="3" >
                                                <Badge
                                                    key={item._id}
                                                    className="btn-round"
                                                    variant="warning"
                                                    style={{height:"36px",width:"-webkit-fill-available",float:"right"}}
                                                >
                                                    {item.nom}
                                                </Badge>
                                            </Col>
                                            <Col md="3" >
                                                 <Select
                                                    key={index}
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    value={AdminGretherSelected[item._id]}
                                                    onChange={
                                                        (value) => {
                                                            SetAdminGretherSelected(AdminGretherSelected.filter(current => current.id !== item._id));
                                                            SetAdminGretherSelected(
                                                                oldArray => [...oldArray, {id:item._id,value:value.value}]
                                                            );
                                                        }
                                                    }
                                                    options={AdminGrether.map((adminUser)=>new Object({value:adminUser._id,label:adminUser.prenom.concat(' ',adminUser.nom)}))}
                                                    placeholder="Admin?"
                                                />
                                            </Col>
                                        </Row>
                                    ))
                                }
                            </Card.Footer>
                            <Card.Footer>
                                <Button
                                    variant="danger"
                                    disabled={!(AdminGretherSelected.length===AllSociete.length)}    
                                    onClick={()=>onClickSubmit()}
                                >
                                    Valider
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Container>
                </div>
            </div>
    );
}

export default RegisterPageStep2;
