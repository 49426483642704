import React from 'react';
import {
    Container,
    Col,
    Row,
} from "react-bootstrap";
import logo from './logo.gif';

const Error500 = () => {
    return (

        <Container >
            <Row>
                <Col md="12" style={{textAlign:'center'}}>
                    <img src={logo} alt="" style={{ width: "30%" }} />
                </Col>
                <Col md="12">
                <h2  className="headline font-primary">{"Page indisponible"}</h2>
                </Col>
                <Col md="12">
                    <p className="sub-content">{"La page que vous tentez d'atteindre n'est actuellement pas disponible. Cela peut être dû au fait que la page n'existe pas ou qu'elle a été déplacée.."}</p>
                </Col>
            </Row>
        </Container>


    );
};

export default Error500;