import React,{useState,useEffect } from "react";
import { Row, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { handleAddressAutoComplete } from "components/MapsAutocomplete/AdressGoogleGetValue";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Select from "react-select";
import Options from "lib/Constant/interface/forms"
import TypeDacces from "lib/Constant/Status/typeDacces.constant";
import BDCService from "services/Api/Controllers/BdcService";


// eslint-disable-next-line react/display-name
const Step2 = React.forwardRef((props, ref) => {
  //@ts-ignore.

  const CurrentIdBDC=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.offre:undefined;

  const [etage, setEtage] = useState("")
  const [phone, setPhone] = useState("");
  const [portable, setPortable] = useState("");
  
  const [typeDacces, settypeDacces] = useState<Options | undefined>();
  const [horaireOuverture,sethoraireOuverture]=useState("9:00");
  const [horaireclose,sethoraireclose]=useState("18:00");
  const [adresse, setAdresse] = useState(null);
  const [alertState, setAlertState] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [allLocation,setAllLocation]=useState("");
  const [loading,setLoading]=useState(true);
  const [loadingEdit, setloadingEdit] = useState(true)

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }
  const isValidate=()=>{
      if(etage!==""
      &&phone!==""
      &&portable!==""
      &&typeDacces!==undefined
      &&horaireOuverture!==""
      &&horaireclose!==""
      &&adresse!==null)
      {
        return true; 
      }else{
        alertEvent("Vous devez indiquer toutes les informations !")
      }
      return false;  
  }
  const saveData=()=>{
    const data={etage:etage,phone:phone,portable:portable,typeDacces:typeDacces?.label,open:horaireOuverture,close:horaireclose,adresse:adresse,allLocation:allLocation}
    return data;
  }
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidate() 
    }, state: saveData(),
  }));

  useEffect(() => {
    (adresse===null||adresse===undefined)? null:handleAddressAutoComplete(adresse).then(data=> setAllLocation(data))
    return () => {
      setAllLocation("")
    }
  }, [adresse])
  
  useEffect(() => {

    let isMounted=true;
    async function loadDataCurrent() {
      const EditVal = await BDCService.getByID(CurrentIdBDC);
      if(isMounted&&loadingEdit)  {

        setEtage(EditVal?.livraison?.etage)
        setPhone(EditVal?.livraison?.phone)
        setPortable(EditVal?.livraison?.portable)
        settypeDacces(TypeDacces.filter((item:any)=>item.label===EditVal?.livraison?.typeDacces)[0])
        sethoraireOuverture(EditVal?.livraison?.open)
        sethoraireclose(EditVal?.livraison?.close)
        setAllLocation(EditVal?.livraison?.allLocation)
        setAdresse(EditVal?.livraison?.adresse)
        setloadingEdit(false);
        setLoading(false)
    }
    }
    if(isMounted&&loading){
        if(props['wizardData']?.FACTURATION!==undefined && CurrentIdBDC===undefined){
        setPhone(props['wizardData']?.FACTURATION?.phone);
        setPortable(props['wizardData']?.FACTURATION?.portable);
        setAdresse(props['wizardData'].FACTURATION?.adresse);
        setLoading(false);
        }else if(CurrentIdBDC!==undefined&&loadingEdit){
          loadDataCurrent();
        }
    }
    return () => {
      isMounted=false;
    }
  }, [props['wizardData']])
  return (
    <>
    {alertState}
    <div className="wizard-step" ref={ref}>
      <p className="text-center">Informations Prospect</p>
        <Row >
          <Col md={{ span: 5, offset: 1 }}>
          <FormGroup>
              <FormLabel>
              Portable
              </FormLabel>
              <FormControl
                type="text"
                name="portable"
                value={portable}
                onChange={(e)=>setPortable(e.target.value)}
              />
              {portable !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{ span: 5}}>
          <FormGroup>
              <FormLabel>Telephone</FormLabel>
              <FormControl 
                onChange={(e)=>setPhone(e.target.value)}
                type="text" 
                name="tel" 
                value={phone}
              />
               {phone !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
            
          </Col> 
          <Col md={{ span: 5, offset: 1 }}>
          <FormGroup>
          <FormLabel>horaire d&apos;ouverture</FormLabel>
          <FormControl 
                onChange={(e)=>sethoraireOuverture(e.target.value)}
                type="text" 
                name="horaireOuverture" 
                value={horaireOuverture}
              />
               {horaireOuverture !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{ span: 5}}>
          <FormGroup>

          <FormLabel>horaire d&apos;Fermeture</FormLabel>
          <FormControl 
                onChange={(e)=>sethoraireclose(e.target.value)}
                type="text" 
                name="horaireclose" 
                value={horaireclose}
              />
               {horaireclose !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          <Col md={{offset:1,span:5}}>
              <FormGroup className="has-success">
              <FormLabel>Types d’accès</FormLabel>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="typeDacces"
                        value={typeDacces}
                        onChange={(value) => settypeDacces(value)}
                        options={TypeDacces}
                        placeholder="Types d’accès"
                      />
              </FormGroup>
              {typeDacces !== undefined ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
          </Col>
          <Col md="5">
          <FormGroup>
              <FormLabel>Etage</FormLabel>
              <FormControl 
                onChange={(e)=>setEtage(e.target.value)}
                type="text" 
                name="etage" 
                value={etage}
              />
               {etage !== "" ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
          
         <Col md={{ span: 10, offset: 1 }}>
            <FormGroup>
              <FormLabel>Adresse</FormLabel>
              <GooglePlacesAutocomplete           
                  autocompletionRequest = {{componentRestrictions:{ country:"fr" }}}
                  apiOptions={{ language: 'fr', region: 'fr' }}
                  selectProps={{
                      placeholder: "...." ,
                      value:adresse,
                      onChange: setAdresse
                  }}
                />
               {adresse !== null ? null : (
                      <label className="error" style={{ color: "red", textTransform: "none" }}>
                        Ce champ ne peut pas etre vide.
                      </label>
                    )}
            </FormGroup>
          </Col>
        </Row>

    </div>
    </>
  );
});

export default Step2;
