/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
// react-bootstrap components
import {
  Badge,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable";
import SelectColumnFilter from "components/ReactTable/SelectColumnFilter";
import { format } from 'date-fns';
import BDCService from "services/Api/Controllers/BdcService";
import StatusBdc from "lib/Constant/Status/Propal.constant";
import ProspectService from "services/Api/Controllers/ProspectService";
import AuthService from "services/Api/Controllers/AuthService";
import SweetAlert from "react-bootstrap-sweetalert";
import { upperCase} from "lib/outilsForms"
import MailService from "services/Api/Controllers/MailService";
import EmailSend from "components/Email/SendMail";
import { useHistory } from "react-router-dom";
import ColorBadge from "lib/Constant/Status/ColorBadge.constant";
export default function TableBDC() {

let history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [DataTableProspect, setDataTableProspect] = useState([]);
  const [data, setData] = useState([]);
  const [nameUser, setNameUser] = React.useState([]);
  const [loadingUserName, setloadingUserName] = useState<Boolean>(true);

  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingProspect, setLoadingProspect] = useState<Boolean>(true);
  const [loadingDataBdcTable, setloadingDataBdcTable] = useState<Boolean>(true);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [alert, setalert] = useState(null);
  const [isMail,setIsmail]=useState(null);


  React.useEffect(() => {
    window.addEventListener("resize", () => { setWidth(window.innerWidth) });
    return () => window.removeEventListener("resize", () => { setWidth(window.innerWidth) });
  });

  useEffect(() => {
    let isSubscribed = true;
    async function loadDataProspect() {
      const prospect = await ProspectService.getAllData();
      if (isSubscribed) {
        setDataTableProspect(prospect);
        setLoadingProspect(false);
      }
    }
    
    async function loadDataBdc() {
      const Bdc = await BDCService.getAllbdc();
      if (isSubscribed) {
        setDataTable(Bdc);
        setloadingDataBdcTable(false);
      }
    }
    async function loadDataUserName() {
      let username = await AuthService.getnameid();
      if (isSubscribed) {
        setNameUser(username);
        setloadingUserName(false);
      }
    }
    if (loadingProspect) {
      loadDataProspect();
    }

    if (loadingDataBdcTable) {
      loadDataBdc()
    }
    if (loadingUserName) {
      loadDataUserName();
    }

    if (!loadingDataBdcTable && !loadingProspect && !loadingUserName) {
      dataGenerate();
      setLoading(false);
    }

    return () => {
      isSubscribed = false
    };
  }, [dataTable, DataTableProspect]);

  // eslint-disable-next-line no-unused-vars
  const actionButton = (keys: string, attributeAtt: string) => {

    return (
      <div className="actions-right">
        {/* View */}
        <Button
          onClick={() => window.open(`${process.env.REACT_APP_API_BASE_URL}pdfBDC/${keys}.pdf`, "_blank")}
          variant="info"
          className="text-info btn-link like"
        >
          <i className="fa fa-eye" />
        </Button>

        {/* Edition */}

        <Button variant="danger" onClick={() => { eventAlert(keys) }} className="text-danger btn-link edit" >
          <i className="fa fa-trash" />
        </Button>
      </div>
    )
  }
  const actionButtonSend = (keys: string,isStatus:Boolean) => {
    return (
      <div className="actions-right">
        {/* View */}
      
        <Button variant="primary" onClick={() => { 
        if(isStatus){
          console.log(keys)
          MailAlert(keys)
        }else{
          
          NoMailAlert()
        } 
        }} className="text-primary btn-link edit" >
          <i className="fa fa-envelope" />
        </Button>
      </div>
    )
  }
  const actionsDuplicate = (propal:string,offre:string) => {
 
    return (
      <div className="actions-right">
        {/* View */}
      
        <Button 
          variant="primary"
          onClick={() => { DublicateAlerte(propal,offre)}} 
          className="text-primary btn-link edit" 
        >
        <i className="fas fa-copy"></i>
        </Button>
      </div>
    )
  }
  const DuplicateTo=(propal,offre)=>{
      history.push(`/admin/add_bdc/${propal}/${offre}`)
  }
  const DublicateAlerte = (propal,offre) => {
    setalert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Voulez vous dupliquer ce bon de commande?"
        onConfirm={() => { DuplicateTo(propal,offre)}}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      />
    )
  }
  const dataGenerate = () => {
    setData(
      dataTable.map((prop, key) => {
        const currentProspect = DataTableProspect.filter((item: any) => item._id == prop.prospect)
        let nameAttribute = nameUser.find((o) => o._id === currentProspect[0].attributeAtt)
        const isStatus=(prop.status!=="3"&&prop.status!=="4")?true:false;
        return {
          id: key + 1,
          type: prop.type,
          nom: currentProspect[0]['nom'],
          societe: currentProspect[0]['societe'],
          status: StatusBdc.find((o) => o.value === prop.status).label,
          modifierle: new Date(prop.lastModifiedAt),
          key: prop._id,
          attributeAtt: (nameAttribute !== undefined) ? (nameAttribute.prenom.concat(" ").concat(nameAttribute.nom)) : ("Non Disponible"),
          actions: (actionButton(prop._id, prop.attributeAtt)),
          actionsSend: (actionButtonSend(prop._id,isStatus)),
          actionsDuplicate: (actionsDuplicate(prop.propal._id,prop._id) ),
        };
      })
    )
  }
  const eventAlert = (id) => {
    setalert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Vous êtes sûr ?"
        onConfirm={() => { successDelete(); DeleteBdc(id) }}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui, supprimez-le !"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne serez plus en mesure de récupérer cette Bdc !
            </SweetAlert>
    );
  }
  const MailTo=(id)=>{
    async function loadEditData() {
      const result= await MailService.GetnewwbdcMailer(id);
      setIsmail(result);
  }  
  loadEditData();
  }
  const MailAlert = (id) => {
    setalert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title="Voulez vous envoyer un Mail au client?"
        onConfirm={() => { MailTo(id)}}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
            </SweetAlert>
    );
  }
  const NoMailAlert = () => {
    setalert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Vous ne pouvez pas renvoyer l'e-mail après l'acceptation de la proposition."
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris"
      >
      </SweetAlert>
    );
  }
  const successDelete = () => {
    setalert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Supprimé!"
        onConfirm={() => window.location.reload(false)}
        onCancel={() => window.location.reload(false)}
        confirmBtnBsStyle="primary"
      >
        La Bdc a été supprimé.
      </SweetAlert>
    );
  };
  const DeleteBdc = (id) => {
    async function ProduitRemove(id) {
      await BDCService.deletebdc(id);
    }
    ProduitRemove(id);
  }

  if(isMail!==null) return <EmailSend data ={isMail} fromSource="table"/>

  return (
    <Container fluid>
      {alert}
      <Row>
        <Col md="12">
          {(loading) ?
            <Spinner animation="border" variant="primary" />
            : ((width < 600) ?
              (<ReactTable
                data={data}
                columns={[
                  {
                    Header: "Societe",
                    accessor: "societe",
                  }, 
                  {
                    Header: "Type",
                    accessor: "type",
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                    Cell: ({ cell: { value } }) =>
                      <Badge variant="info">{upperCase(value)}</Badge>
                  },
                  {
                    Header: "Envoi",
                    accessor: "actionsSend",
                    Filter:false

                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    Filter:false
                  },
                ]}
              />)
              : (<ReactTable
                data={data}
                columns={[
                  
                  {
                    Header: "Societe",
                    accessor: "societe",
                  },
                  {
                    Header: "Type",
                    accessor: "type",
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                    Cell: ({ cell: { value } }) =>
                      <Badge variant="info">{upperCase(value)}</Badge>
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                    Cell: ({ cell: { value } }) =>
                      <Badge variant={`${ColorBadge(value)}`}>{value}</Badge>
                  },
                  {
                    Header: "Attribué à",
                    accessor: "attributeAtt",
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                    Cell: ({ cell: { value } }) => <Badge variant="secondary">{value}</Badge>
                  },
                  {
                    Header: "Modifier le",
                    accessor: "modifierle",
                    Cell: ({ cell: { value } }) => format(new Date(value), 'dd/MM/yyyy')
                  }, {
                    Header: "Envoi",
                    accessor: "actionsSend",
                    Filter:false
                  },{
                    Header: "Dupliquer",
                    accessor: "actionsDuplicate",
                    Filter:false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    Filter:false

                  },
                ]}
              />)
            )
          }
        </Col>
      </Row>
    </Container>
  );
}
