import React from 'react';
import { Viewer,Worker } from '@react-pdf-viewer/core';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

interface FullScreenButtonExampleProps {
    fileUrl: string;
}

const ViewPdf: React.FC<FullScreenButtonExampleProps> = ({url}) => {
    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;

    return (
       
       <div style={{border: '1px solid rgba(0, 0, 0, 0.3)', display: 'flex',flexDirection: 'column', height: '100%'}} >
         <div style={{alignItems: 'center',backgroundColor: '#eeeeee',borderBottom: '1px solid rgba(0, 0, 0, 0.1)',display: 'flex',padding: '4px',}}>
            <EnterFullScreenButton />
         </div>
            <div style={{flex: 1,overflow: 'hidden',}}>
                <div className="App">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                        <div id="pdfviewer">
                            <Viewer
                                fileUrl={url}
                                plugins={[fullScreenPluginInstance,]}
                            />
                        </div>
                    </Worker>
                </div>
            </div>
       </div>
    );
};

export default ViewPdf;