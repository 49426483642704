import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// core components
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
//import AdminFooter from "components/Footers/AdminFooter";
// dinamically create dashboard routes
// @ts-ignore
import routes from "routes/admin.routes.ts";
import image3 from "assets/img/full-screen-image-3.jpg";
import Auth from "services/Api/Controllers/AuthService";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import AuthService from "services/Api/Controllers/AuthService";


export default function Admin() {
  const [sidebarImage, ] = React.useState(image3);
  const [sidebarBackground,] = React.useState("black");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      else if (prop.state==="AdminConnected") {
        if(AuthService.getLevelAdmin()===0)
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
        }else if (prop.state==="accesProduct") {
        if(AuthService.getAccesProduct()===true)
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      }else if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
            
          />
        );
      } else {
        return null;
      }
    });
  };

  let RedirectionConnected;

  if (Auth.isLogged()){
      if(SocieteServices.selectedSociety()){
        RedirectionConnected=<AdminComponment/>
      }else{
        RedirectionConnected=<Redirect to="/auth/select-societe"/>
      }
  }else{
    RedirectionConnected=<Redirect to="/auth/login"/>
  }

  return (
    <>
    {RedirectionConnected}
    </>
  );

  function AdminComponment (){
    return (<div className="wrapper">
             <Sidebar
               routes={routes}
               image={sidebarImage}
               background={sidebarBackground}
             />
             <div className="main-panel">
               <AdminNavbar />
               <div className="content">
                 <Switch>{getRoutes(routes)}</Switch>
               </div>
               {/* <AdminFooter /> */}
               <div
                 className="close-layer"
                 onClick={() =>
                   document.documentElement.classList.toggle("nav-open")
                 }
               />
             </div>
           </div>
         )
   } 
}

