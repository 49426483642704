import React from 'react';
import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ValidationForms from "components/Forms/Register/FormRegister";


const RegisterPage=()=> {

  return (
    <>
      <div
        className="register-page section-image"
        data-color="black"
      >
        <div className="content d-flex align-items-center">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="8">
                    <div className="header-text">
                      <Card.Title as="h2">
                        Creation d&apos;un compte 
                      </Card.Title>
                      <hr></hr>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
              <ValidationForms/>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
