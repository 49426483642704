import React,{useState,useEffect } from "react";
import { Row, Col, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import ProspectService from "services/Api/Controllers/ProspectService";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";


// eslint-disable-next-line react/display-name
const Step1 = React.forwardRef((props, ref) => {
  //@ts-ignore.
  const CurrentIdProspect=(props.match!==null || props.match!==undefined)?props.match.params.id:undefined;
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [societe, setSociete] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [adresse, setAdresse] = useState(null);

  const [loadingEdit,setloadingEdit ] = useState(true);
  const [alertState, setAlertState] = useState(null);
  const [toRedirect, settoRedirect] = useState(false)

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return true 
    }, state: {
      id:CurrentIdProspect,
    },
  }));

  useEffect(() => {
    let isSubscribed = true;
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        const prospectEditVal = await ProspectService.getByID(CurrentIdProspect);
        if(isSubscribed)  {
          setNom(prospectEditVal.nom);
          setPrenom(prospectEditVal.prenom);
          setSociete(prospectEditVal.societe);
          setEmail(prospectEditVal.email);
          setPhone(prospectEditVal.tel);
          setAdresse(prospectEditVal.localisation);
        }
      }
      
    }
    if (loadingEdit) {
      if(CurrentIdProspect!==undefined){
        loadEditData().catch(()=>{
          setAlertState(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Vous n'avez pas accès à cette section!"
              onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
              onCancel={() =>{settoRedirect(true);setAlertState(null)}}
              confirmBtnBsStyle="info"
            >
            </SweetAlert>
          );
        });
      }
    }
    return () => {isSubscribed=false; };
  }, [])

  if(toRedirect) return (<Redirect to="/admin/prospect" />)
  
  return (
    <>
    {alertState}
    <div className="wizard-step" ref={ref}>
      <p className="text-center">Informations Prospect</p>
      {(loadingEdit)?<Spinner animation="border" variant="primary" />:
        <Row >
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>Nom</FormLabel>
              <FormControl 
                disabled
                type="text" 
                name="nom" 
                value={nom}
              />
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <FormLabel>Prenom</FormLabel>
              <FormControl 
                disabled
                type="text" 
                name="prenom" 
                value={prenom}
              />
            </FormGroup>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>Societe</FormLabel>
              <FormControl 
                disabled
                type="text" 
                name="societe" 
                value={societe}
              />
            </FormGroup>
          </Col>
          <Col md={5}>
          <FormGroup>
              <FormLabel>Tel</FormLabel>
              <FormControl 
                disabled
                type="text" 
                name="tel" 
                value={phone}
              />
            </FormGroup>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <FormGroup>
              <FormLabel>
                Email
              </FormLabel>
              <FormControl
                type="email"
                name="email"
                value={email}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={{ span: 5}}>
            <FormGroup>
              <FormLabel>
                Societe Associe
              </FormLabel>
              <FormControl
                type="email"
                name="societe"
                value={SocieteServices.getCurrentSocieteName()}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={{ span: 10, offset: 1 }}>
            <FormGroup>
              <FormLabel>Adresse</FormLabel>
              <FormControl 
                disabled
                type="text" 
                name="adresse" 
                value={(adresse===null)?"Pas de valeur":adresse.label}
              />
              
            </FormGroup>
          </Col>
        </Row>
      }
    </div>
    </>
  );
});

export default Step1;
