import React,{useState,useEffect} from "react";

// react-bootstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable";
import ProductService from "services/Api/Controllers/ProductService";
import AddProductsHelper from "components/Forms/Add/ElementWithName/AddProductsHelper";


export default function TableMarque() {


  const [dataTablemarque,setDataTablemarque]=useState([]);
  const [data,setData]=useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [edit, setedit] = useState<Boolean>(false);
  const [EditKey, setEditKey] = useState<String>("");
  const [loadingmarqueTable, setloadingmarqueTable] = useState<Boolean>(true); 
  const [alert, ] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState(false);
 

  useEffect(() => {
    let isSubscribed = true;

    async function loadDatamarque() {
        const Produit = await ProductService.getallMarque();
        if(isSubscribed)  {
          setDataTablemarque(Produit);
          setloadingmarqueTable(false);
        }
    }
    
  
    if(loadingmarqueTable){loadDatamarque()}
 
    if(!loadingmarqueTable){
      dataGenerate();    
      setLoading(false);
    }
    if(response){
        setLoading(true);
        loadDatamarque();
        dataGenerate();
        setLoading(false);
    }
    return () => {isSubscribed=false; };
  }, [dataTablemarque,response]);

  const actionButton= (keys:Object) =>{

    return (
      <div className="actions-right">
        {/* Edition */}
        <Button 
            variant="warning"  
            onClick={()=>{
                setedit(true);
                setEditKey(keys);
            }}
            className="text-warning btn-link edit" >
            <i className="fa fa-edit"/>
        </Button>
      </div>
    )
  }

  const dataGenerate=()=>{
    setData(
        dataTablemarque.map((prop, key) => {
           console.log(prop)
      return {
        id: key,
        nom: prop.label,
        value:prop.value,
        actions: (
          actionButton(prop.value)
        ),
      };
    })
  )
}

  return (
    <>

    {alert}
    {(edit)?(<AddProductsHelper show={edit} setShow={setedit} edit={EditKey} type="marque"/>):null}
    <Container fluid>
      <Row>
        <Col md="12">
          {(loading)?
                    <Spinner animation="border" variant="primary" />
                      :<ReactTable 
                          data={data}
                          columns={[
                            {
                              Header: "Nom",
                              accessor: "nom",
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              Filter:false
                            },
                          ]}
                        />
            }
        </Col>
      </Row>
      </Container>
    </>
  );
}
