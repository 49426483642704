
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

// @ts-ignore
import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";
import Auth from "services/Api/Controllers/AuthService";
import GuestAcces from "view/Guest/GuestAcces";
require('dotenv').config()


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      
      <Route path="/guest/:id" render={(props) => <GuestAcces {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      {(Auth.isLogged()) ?(<Redirect from="/"  to="/admin/dashboard"/>)
                         :<Redirect from="/"  to="/auth/login" />}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
