import React, { useEffect, useState } from "react";
import { emailValidation, minLength, phoneValidation } from 'lib/outilsForms'

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Spinner,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import Options from "lib/Constant/interface/forms"
import { Redirect } from "react-router-dom";
import StatusProspect from "lib/Constant/Status/Propsect.constant";
import StatusSource from "lib/Constant/Status/Source.constant";
import SocieteServices from "services/Api/Controllers/SocieteServices";
import TypeOption from "lib/Constant/interface/forms";
import AuthService from "services/Api/Controllers/AuthService";
import ProspectService from "services/Api/Controllers/ProspectService";
import SweetAlert from "react-bootstrap-sweetalert";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import AddPicture from 'components/Forms/Add/Picture/AddPicture';
import GenreStatus from "lib/Constant/Status/Genre.constant";
import { handleAddressAutoComplete } from "components/MapsAutocomplete/AdressGoogleGetValue";

export default function FormProspect({match}) {

  const [nomState, setNomState] = useState(true);
  const [prenomState, setPrenomState] = useState(true);
  const [societeState, setSocieteState] = useState(true);
  const [phoneState, setPhoneState] = useState(true);
  const [emailState, setEmailState] = useState(true);


  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [societe, setSociete] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [statusDuProspect, setStatusDuProspect] = useState<Options | undefined>(StatusProspect[0]);
  const [source, setSource] = useState<Options | undefined>(undefined);
  const [societeCurrent, setsocieteCurrent] = useState<TypeOption>();
  const [adresse, setAdresse] = useState(null);
  const [userSelected, setUserSelected] = useState<TypeOption>();
  const [genreSelected, setGenreSelected] = useState<Options | undefined>(undefined);

  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingEdit, setLoadingEdit] = useState<Boolean>(true);
  const [loadingDataUser,setLoadingDataUser]=useState<Boolean>(true);
  const [alert, setAlert] = React.useState(null);
  const [succesForm, setSuccesForm] = useState("")
  const [end, setEnd] = React.useState(false);
  const [allLocation,setAllLocation]=useState("");
  

  const authoriseToSubmit=()=>{
   
    const  isPhoneValid = phone !== '' && phoneValidation(phone) ;
    const  isEmailValid = email !== '' && emailValidation(email);
    const  isNomValide = nom !== ''
    const  isPrenomValide = prenom !== ''
    const  isSocieteValide = societe !== ''
    setSocieteState(isSocieteValide);
    setEmailState(isEmailValid)
    setNomState(isNomValide);
    setPrenomState(isPrenomValide);
    setPhoneState(isPhoneValid);
    
    if (
      isSocieteValide
      &&isPhoneValid
      && isEmailValid 
      && isPrenomValide 
      && isNomValide
      && (statusDuProspect !== undefined)
      && (source !== undefined)
      && (genreSelected !== undefined)
      && (userSelected !== undefined)){
        return true; 
      }
      return false;
      
  }

  
  const onSubmitProspect = () => {

    
    var data_current =new Object({
      genre:genreSelected.label,
      nom: nom,
      prenom: prenom,
      societe: societe,
      tel: phone,
      email: email,
      description: description,
      localisation: adresse,
      allLocation:allLocation,
      status: statusDuProspect.value,
      societeAssocie: societeCurrent.value,
      attributeAtt: userSelected.value,
      source: source.value,
    })
  

    async function data() {
      const boolres = await ProspectService.saveToDB(data_current)
      setSuccesForm(boolres);
      setAlert(  
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="L'ajout a été effectué avec succès"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Suivant"
        >
        </SweetAlert>
        )
    }
    data();
  }
  const onUpdateProspect = () => {

    var data_current = new Object({
      idProspect:match.params.id,
      genre:genreSelected.label,
      nom: nom,
      prenom: prenom,
      societe: societe,
      tel: phone,
      email: email,
      description: description,
      localisation: adresse,
      allLocation:allLocation,
      status: statusDuProspect.value,
      societeAssocie: societeCurrent.value,
      attributeAtt: userSelected.value,
      source: source.value,
    })

    
    async function data() {
      const boolres = await ProspectService.updateData(data_current)
      setSuccesForm(boolres);
      setAlert(  
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="La Modification a été effectué avec succès"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Suivant"
        >
        </SweetAlert>
        )
    }
    data();
  }

  useEffect(() => {
 

    setsocieteCurrent({ value: SocieteServices.getSociete(), label: SocieteServices.getCurrentSocieteName() });
    
    async function loadDataUsers() {
      if (loadingDataUser) {
        const societes = await AuthService.getAllBySociete(SocieteServices.getSociete());
          setAllUsers(societes);
          setLoadingDataUser(false);
      }
    }
    
    async function loadEditData() {
      if (loadingEdit) {
        setLoadingEdit(false)
          const prospectEditVal = await ProspectService.getByID(match.params.id);
          setNom(prospectEditVal.nom);
          setPrenom(prospectEditVal.prenom);
          setSociete(prospectEditVal.societe);
          setEmail(prospectEditVal.email);
          setPhone(prospectEditVal.tel);
          setDescription(prospectEditVal.description);
          setAdresse(prospectEditVal.localisation);
          setStatusDuProspect(StatusProspect.find((o) => o.value === prospectEditVal.status))
          setSource(StatusSource.find((o)=>o.value===prospectEditVal.source))
          setGenreSelected(GenreStatus.find((o)=>o.label===prospectEditVal.genre))

          let UserEditSelected=allUsers.find((o)=>o._id===prospectEditVal.attributeAtt);
          let valSet:TypeOption={ value: UserEditSelected["_id"], label: UserEditSelected["prenom"].concat(' ', UserEditSelected["nom"])};
          setUserSelected(valSet);
        
      }
    }
  
    if(loadingDataUser){
      loadDataUsers();
    }
    if (loadingEdit &&loadingDataUser===false) {
      if(match.params.id!==undefined){
        loadEditData().catch(()=>{
          match.params.id=undefined 
        });
      }
    }

    if(match.params.id===undefined){
      if(!loadingDataUser){
        setLoading(false);
      }
    }else{
      if(!loadingDataUser&&!loadingEdit){
        setLoading(false);
      }
    }
   
  }, [allUsers,loadingDataUser]);

  useEffect(() => {
    (adresse===null)? null:handleAddressAutoComplete(adresse).then(data=> setAllLocation(data))
    return () => {
      setAllLocation("")
    }
  }, [adresse])
  const hideAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={(match.params.id!==undefined)?"Voulez-vous modifier le logo du prospect?"
                                            :"Voulez-vous ajouter le logo du prospect?"}
        onConfirm={() => {
          hideAlertHide();
        }}
        onCancel={() => byeAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
      </SweetAlert>
    );
  };
  const hideAlertHide = () => {
    setAlert(null);
    setEnd(false);   
  }
  const byeAlert = () => {
    setAlert(null);
    setEnd(true);   
  }



  
  const renderButtons= ()=> {
    if (match.params.id===undefined) 
      return (
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            const  isValid = authoriseToSubmit();
            if(isValid){
              onSubmitProspect();
            }
          }}
        >
        Ajouter
        </Button>
      )
    else {
      return (
        <>
        <Button
        variant="warning"
        style={{marginRight:"10px"}}
        onClick={(e) => {
          e.preventDefault();
          const  isValid = authoriseToSubmit();
          if(isValid){
            onUpdateProspect();
          }
        }}
      >
        Modifier
      </Button>
      </>
       ) 
    }
  }
  
  if (succesForm !== ""&&end===false &&alert===null) {
    if (match.params.id===undefined) 
      return ( <AddPicture id={succesForm} type="prospect" gabari={undefined}/>)
    else
      return  ( <AddPicture id={match.params.id} type="prospect" gabari={undefined}/>)
  }
  if (succesForm !== "" && end===true) {
    return (<Redirect to="/admin/prospect"/>)
  }

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  } else
    return (
      
      <Container fluid>
      {alert}
        <Row className="justify-content-md-center ml-auto mr-auto">
          <Col lg="12">
            <Form
              action=""
              className="form-horizontal"
              id="Validation"
              method=""
            >
              <Card>
                <Card.Header style={{ textAlign: "center" }}>
                  <Card.Title as="h4">
                  {(match.params.id===undefined)?("Ajouter un Prospect"):("Modifier un Prospect")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Societe Associe */}
                  <Row>
                    <Col md={{ span: 5, offset: 4 }}>
                      <Form.Group className="has-success">
                        <label> Societé Associé <span className="text-danger">*</span></label>
                        <Form.Control
                          name="Societe"
                          type="text"
                          value={societeCurrent.label}
                          disabled={true}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Status du Prospect */}
                  <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                      <Form.Group className="has-success">
                        <label> Status du Prospect <span className="text-danger">*</span></label>
                      <Form.Control
                          name="Societe"
                          type="text"
                          value={statusDuProspect.label}
                          disabled={true}
                        ></Form.Control>
                        </Form.Group>
                    </Col>


                  {/* Source */}
                  <Col md="3">
                    <Form.Group>
                      <label>Source <span className="text-danger">*</span></label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Source"
                        value={source}
                        onChange={(value) => setSource(value)}
                        options={StatusSource}
                        placeholder="Source"
                      />
                      {source ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                  {/* Attribue a*/}
                  <Col md="3">
                      <Form.Group>
                        <label>Attribué à <span className="text-danger">*</span></label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="attribuea"
                          value={userSelected}
                          onChange={(value) => setUserSelected(value)}
                          options={allUsers.map((adminUser) => new Object({ value: adminUser._id, label: adminUser.prenom.concat(' ', adminUser.nom) }))}
                          placeholder="Selectionner"
                        />
                        {userSelected ? null : (
                          <label className="error" style={{ color: "red", textTransform: "none" }}>
                            Ce champ doit être selectionner.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Genre */}
                  <Row>
                  <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Genre <span className="text-danger">*</span>
                  </Form.Label>
                  <Col md="7">
                    <Form.Group>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="Genre"
                        value={genreSelected}
                        onChange={(value) => setGenreSelected(value)}
                        options={GenreStatus}
                        placeholder="Genre"
                      />
                      {genreSelected ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                  </Row>
                  {/* Nom */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Nom <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="7">
                      <Form.Group className={nomState ? "has-success" : "has-error"}>
                        <Form.Control
                          name="nom"
                          type="text"
                          value={nom}
                          onChange={(e) => {
                            setNom(e.target.value);
                            if (minLength(e.target.value, 2)) {
                              setNomState(true);
                            } else {
                              setNomState(false);
                            }
                          }}
                        ></Form.Control>
                        {nomState ? null : (
                          <label className="error" style={{ color: "red", textTransform: "none" }}>
                            Ce champ doit être selectionner.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Prenom */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Prenom <span className="text-danger">*</span>
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className={prenomState ? "has-success" : "has-error"}>

                        <Form.Control
                          name="prenom"
                          type="text"
                          value={prenom}
                          onChange={(e) => {
                            setPrenom(e.target.value);
                            if (minLength(e.target.value, 2)) {
                              setPrenomState(true);
                            } else {
                              setPrenomState(false);
                            }
                          }}
                        ></Form.Control>
                        {prenomState ? null : (
                          <label className="error" style={{ color: "red", textTransform: "none" }}>
                            Ce champ doit être selectionner.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* societe */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Societe<span className="text-danger"> *</span>
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className={societeState ? "has-success" : "has-error"}>
                        <Form.Control
                          name="societe"
                          type="text"
                          value={societe}
                          onChange={(e) => {
                            setSociete(e.target.value);
                            if (minLength(e.target.value, 2)) {
                              setSocieteState(true);
                            } else {
                              setSocieteState(false);
                            }
                          }}
                        ></Form.Control>
                          {societeState ? null : (
                          <label className="error" style={{ color: "red", textTransform: "none" }}>
                            Ce champ doit être selectionner.
                          </label>
                          )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Telephone */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Telephone <span className="text-danger">*</span>
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className={phoneState ? "has-success" : "has-error"}>

                        <Form.Control
                          name="telephone"
                          type="text"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            if (phoneValidation(e.target.value)) {
                              setPhoneState(true);
                            } else {
                              setPhoneState(false);
                            }
                          }}
                        ></Form.Control>
                        {phoneState ? null : (
                          <label className="error">
                            Ce champ doit être un numéro de téléphone valide.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Email */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      E-Mail <span className="text-danger">*</span>
                            </Form.Label>
                    <Col sm="7">
                      <Form.Group className={emailState ? "has-success" : "has-error"}>
                        <Form.Control
                          name="email"
                          type="text"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (emailValidation(e.target.value)) {
                              setEmailState(true);
                            } else {
                              setEmailState(false);
                            }
                          }}
                        ></Form.Control>
                        {emailState ? null : (
                          <label className="error">
                            Ce champ doit être une adresse électronique.
                          </label>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Description */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Description
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className="has-success">

                        <Form.Control
                          name="prenom"
                          type="text"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        ></Form.Control>

                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Localisation */}
                  <Row>
                    <Form.Label column sm={{ span: 2, offset: 1 }}>
                      Adresse
                      </Form.Label>
                    <Col sm="7">
                      <Form.Group className="has-success">
                          <GooglePlacesAutocomplete
                              
                              autocompletionRequest = {{componentRestrictions:{ country:"fr" }}}
                              apiOptions={{ language: 'fr', region: 'fr' }}
                              selectProps={{
                                  placeholder: "...." ,
                                  value:adresse,
                                  onChange: setAdresse
                              }}
                           />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer
                  className="text-center">
                  {renderButtons()}
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    );
}
