import React, { Fragment } from "react";
import {
    Button,
    Row,
    Col,
    Modal,
    Badge,
    Image,
} from "react-bootstrap";

import {useHistory} from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";
import ProductService from "../../services/Api/Controllers/ProductService"

require('dotenv').config()


export default function ViewProduct({produit,categorie,marque}) {
    const [Response, setResponse] = React.useState<Boolean>(false);
    const [show, setShow] = React.useState(false);
    let history = useHistory();
    const [alert, setAlert] = React.useState(null);

    // pour arrêter l'avertissement d'appeler setState d'un composant non monté
    React.useEffect(() => { 
        if(Response){
            setShow(false);
            window.location.reload(false);
        }
    }, [Response]);

      const successDelete = () => {
        setAlert(
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Supprimé!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="primary"
          >
            Le produit a été supprimé.
          </SweetAlert>
        );
      };

      const hideAlert = () => {
        setAlert(null);
      };
      const Deleteproduit= ()=>{

        async function ProduitRemove() {
            let response = await ProductService.deleteProduit(produit._id);
            setResponse(response);
        }
        ProduitRemove();
      }

      const warningWithConfirmMessage = () => {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Vous êtes sûr ?"
            onConfirm={() => {successDelete();Deleteproduit()}}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            confirmBtnText="Oui, supprimez-le !"
            cancelBtnText="Annuler"
            showCancel
          >
            Vous ne serez plus en mesure de récupérer ce produit !
          </SweetAlert>
        );
      };
    return (
     <>
            {alert}
            <div className="places-sweet-alerts"></div>
            <Button
                onClick={()=>setShow(true)}
                variant="info"
                className="text-info btn-link like"
            >
                <i className="fa fa-eye" />
            </Button>{" "}
            {(produit!==null)?
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
            >
            
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12"  style={{textAlign:"center"}} >
                            <h2 style={{marginTop:"0px",marginBottom:"30px",fontWeight:'bold'}} >
                                Carte du produit
                            </h2>
                        </Col>
                        <Col md="12" style={{textAlign:"center", marginBottom: "20px",float:"left"}}>
                            <Button 
                                style={{marginRight:"10px"}}
                                onClick={()=>history.push(`/admin/edit_product/${produit._id}`) } 
                                variant="primary"
                            > 
                                Modifier
                            </Button>
                            <Button 
                                variant="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    warningWithConfirmMessage()
                                  }}
                            >
                                Supprimer  
                            </Button>
                        </Col>
                        {(produit['pictureUrl']!==undefined)?
                        <Col md="12" style={{overflow:'unset'}}>
                            <Col md={{span:8,offset:3}} style={{marginBottom:'20px'}}>
                            <Image 
                                src={`${process.env.REACT_APP_API_BASE_URL}uploads/${produit['pictureUrl']}`} 
                                style={{borderRadius:'5px'}}
                            fluid />
                            </Col>
                        </Col>
                        :null}
                        <Col md="6" style={{ borderRight: "1px  solid black",textAlign:'center' }}>
                            <Badge variant="secondary" style={{ marginBottom: "20px",padding:'10px'}}>
                                Informations du produit
                            </Badge>
                                <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                    <span style={{ float: "left"}}>
                                        Reference:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span
                                            style={{ float: "left", fontWeight: "bold" }}
                                        >
                                           {produit['reference']}
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                    <span style={{ float: "left"}}>
                                        Categorie:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span style={{ float: "left", fontWeight: "bold" }} >
                                                {categorie}
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "5px"}}>
                                    <Col md="4">
                                        <span style={{ float: "left"}}>
                                            Marque:
                                        </span>
                                    </Col>
                                    <Col md="8" style={{overflow: "auto"}}>
                                        <span style={{ float: "left", fontWeight: "bold" }} >
                                                {marque}
                                        </span>
                                    </Col>
                                </Row>
                        </Col>
                        <Col md="6" style={{textAlign:'center'}}>
                            <Badge variant="secondary" style={{ marginBottom: "20px", padding:'10px'}}>
                                Description
                            </Badge>
                            {produit['description'].map((item,key) => (
                                <Row key={key} style={{ marginBottom: "5px"}}>
                                   {!(item.ismultiple)?
                                   <Fragment>
                                        <Col md="6" style={{overflow: "auto"}}>
                                            <span style={{ fontWeight: "bold",float:"left" }} >
                                              {item.titre}:
                                            </span>
                                        </Col>
                                       
                                        <Col md="6" style={{overflow: "auto"}}>
                                            <Badge variant="secondary" style={{marginRight:'5px'}}>{(item.description==="")?"Sans Description":item.description}</Badge>
                                        </Col>
                                    </Fragment>
                                    :
                                    <Fragment>
                                    <Col md="6" style={{overflow: "auto"}}>
                                    <span style={{ fontWeight: "bold",float:"left" }} >
                                      Choix Multiple:
                                    </span>
                                    </Col>
                                    <Col md="6" style={{overflow: "auto"}}>
                                    {item['tag'].map((tag,kys) =><Badge key={kys} variant="secondary" style={{marginRight:'5px'}}>{tag}</Badge>)}
                                    </Col>
                                    </Fragment>
                                    }   
                                </Row>                     
                            ))}
                        </Col>
                    </Row>
                 
                
                </Modal.Body>
            </Modal>    
            :null
        }
      </>
    )


    
}
