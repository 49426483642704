import ApiService from '../ApiService';
import AuthService from './AuthService';

class AdminService {
  

  static saveToDB(id,societe,admin) {
    return ApiService.post("admin/save",{id_societe:societe,id_user:id,id_admin:admin},AuthService.getToken())
    .then(res=>{
        if (res.status===200){return true;}
        else{return false;} 
       })
    .catch(err=>
        {
            throw err
        })
  }
}

export default AdminService;


