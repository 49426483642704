import React, { Fragment, useEffect, useState } from "react";
import { maxLength, minLength } from 'lib/outilsForms'

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Spinner,
  Col,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import Options from "lib/Constant/interface/forms"
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import ProductService from "services/Api/Controllers/ProductService";
import AddPicture from 'components/Forms/Add/Picture/AddPicture';
import StatusGabarit from "lib/Constant/Status/Gabarit.constant";
import TagsInput from "components/TagsInput/TagsInput";


export default function Formproduit({ match }) {

  const [referenceState, setreferenceState] = useState(true);
  const [, setdescriptionState] = useState(true);

  const [reference, setreference] = useState("");
  const [categorie, setcategorie] = useState<Options | undefined>();
  const [selectedGabari, setselectedGabari] = useState<Options | undefined>();
  const [marqueSelected, setmarqueSelected] = useState<Options | undefined>()
  const [inputListDescription, setinputListDescription] = useState([{ titre: "", ismultiple: false, description: "", tag: [] }]);

  const [AllCategorie, setAllCategorie] = useState([]);
  const [AllMarque, setAllMarque] = useState([]);

  const [alert, setAlert] = React.useState(null);
  const [succesForm, setSuccesForm] = useState("")
  const [end, setEnd] = React.useState(false);
  const [Activelogo, setActivelogo] = React.useState(false);

  const [loading, setLoading] = useState<Boolean>(true);
  const [loadingEdit, setLoadingEdit] = useState<Boolean>(true);
  const [LoadCategorieData, setLoadCategorieData] = useState<Boolean>(true);
  const [LoadMarqueData, setLoadMarqueData] = useState<Boolean>(true);

  const authoriseToSubmit = () => {

    const isreferenceValide = reference !== ''
    const inputListDescriptionValide = (inputListDescription.length === 1) ? inputListDescription[0].titre !== "" : true;
    setdescriptionState(inputListDescriptionValide)
    setreferenceState(isreferenceValide);
    var bool_valide = true;
    if (categorie !== undefined) {
      if (inputListDescription.length == new Number(selectedGabari.value)) {
        inputListDescription.forEach(item => {
          if(!item.ismultiple){if (item.titre === "")bool_valide=false;}
          if(item.ismultiple){if (item.tag.length === 0)bool_valide=false;}
      })
      }
      else {
        bool_valide = false;
      }
      setdescriptionState(bool_valide)
    }

    const isAuthorised = bool_valide && inputListDescriptionValide
      && isreferenceValide && (categorie !== undefined)
      && (selectedGabari !== undefined) && (marqueSelected !== undefined)

    if (!bool_valide) WarningAdd();

    if (isAuthorised) { return true; }

    return false;
  }
  const onSubmitproduit = () => {
    var data_current = new Object({
      categorie: categorie.value,
      marque: marqueSelected.value,
      gabari: selectedGabari.value,
      reference: reference.toUpperCase(),
      description: inputListDescription
    })

    async function data() {
      const boolres = await ProductService.saveProductToDB(data_current)
      setSuccesForm(boolres);
      Popup(boolres);
    }
    data();
  }
  const onUpdateproduit = () => {

    var data_current = new Object({
      categorie: categorie.value,
      marque: marqueSelected.value,
      reference: reference.toUpperCase(),
      gabari: selectedGabari.value,
      description: inputListDescription,
      idProduct: match.params.id
    })
    async function data() {
      const boolres = await ProductService.updateData(data_current)
      setSuccesForm(boolres);
      Popup(boolres);
    }
    data();
  }

  useEffect(() => {
    async function LoadCategorie() {

      if (LoadCategorieData) {
        const product = await ProductService.getallCategorie();
        setAllCategorie(product);
        setLoadCategorieData(false);

      }
    }
    async function LoadMarque() {

      if (LoadMarqueData) {
        const marque = await ProductService.getallMarque();
        setAllMarque(marque);
        setLoadMarqueData(false);

      }
    }
    async function loadEditData() {
      if (loadingEdit) {
        setLoadingEdit(false)
        const produitEditVal = await ProductService.getByIDProduct(match.params.id);
        setreference(produitEditVal.reference);
        setinputListDescription(produitEditVal.description);
        let CategorieSelected = AllCategorie.find((o) => o.value === produitEditVal.categorie);
        setcategorie(CategorieSelected);
        let MarqueSelected = AllMarque.find((o) => o.value === produitEditVal.marque);
        setmarqueSelected(MarqueSelected);
        let SelectedGabari = StatusGabarit.find((o) => new Number(o.value) == produitEditVal.gabari);
        setselectedGabari(SelectedGabari);

      }
    }

    if (LoadCategorieData) {
      LoadCategorie();
    }
    if (LoadCategorieData) {
      LoadMarque();
    }

    if (!LoadMarqueData && !LoadCategorieData && loadingEdit) {
      if (match.params.id !== undefined) {
        loadEditData().catch(() => {
          match.params.id = undefined
        });
      }
    }
    if (match.params.id === undefined) {
      if (!LoadCategorieData && !LoadMarqueData) {
        setLoading(false);
      }
    } else {
      if (!LoadCategorieData && !LoadCategorieData && !loadingEdit) {
        setLoading(false);
      }
    }
  }, [AllCategorie, LoadCategorieData, LoadMarqueData, loadingEdit]);

  const Popup = (boolrest) => {
    if (boolrest !== "" && boolrest !== "existdeja" && boolrest !== "error") {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={match.params.id === undefined ? "L'ajout a été effectué avec succès" : "La modification a été effectué avec succès"}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    }
    if (boolrest === "existdeja") {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="La référence existe deja"
          onConfirm={() => hideAlertHide()}
          onCancel={() => hideAlertHide()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Suivant"
        >
        </SweetAlert>
      )
    }
    if (boolrest === "error") {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Une erreur c'est produite!"
          onConfirm={() => hideAlertHide()}
          onCancel={() => hideAlertHide()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Fermer"
        >
        </SweetAlert>
      )
    }
  }
  const hideAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={(match.params.id !== undefined) ? "Voulez-vous modifier l'image du produit ?"
          : "Voulez-vous ajouter l'image du produit ?"}
        onConfirm={() => {
          viewAddLogo();
        }}
        onCancel={() => byeAlert()}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
      </SweetAlert>
    );
  };
  const byeAlert = () => {
    setAlert(null);
    setEnd(true);
  }
  const hideAlertHide = () => {
    setAlert(null);
    setEnd(false);
  }
  const viewAddLogo = () => {
    setAlert(null);
    setEnd(false);
    setActivelogo(true);

  }
  const hideAlertOriginal = () => {
    setAlert(null);

  };
  const WarningAdd = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Vous devez ajouter : ${selectedGabari.value} Description !`}
        onConfirm={() => hideAlertOriginal()}
        onCancel={() => hideAlertOriginal()}
        confirmBtnBsStyle="primary"
      >
      </SweetAlert>
    );
  };
  const renderButtons = () => {
    if (match.params.id === undefined)
      return (
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            const isValid = authoriseToSubmit();
            if (isValid) {
              onSubmitproduit();
            }
          }}
        >
          Ajouter
        </Button>
      )
    else {
      return (
        <>
          <Button
            variant="warning"
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              e.preventDefault();
              const isValid = authoriseToSubmit();
              if (isValid) {
                onUpdateproduit();
              }
            }}
          >
            Modifier
      </Button>
        </>
      )
    }
  }
  const handleInputDescriptionChange = (e, index) => {
    setdescriptionState(true);
    const { name, value } = e.target;
    if(maxLength(value,30)|| value===""){
    const list = [...inputListDescription];
    list[index][name] = value;
    setinputListDescription(list);
  }else{
    setAlert(
      <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Vous ne pouvez pas dépasser 30 caractères "
      onConfirm={() => {setAlert(null)}}
      onCancel={() => setAlert(null)}
      confirmBtnBsStyle="primary"
      cancelBtnBsStyle="danger"
      confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }
  };
  

  if (Activelogo && alert === null) {
    if (match.params.id === undefined)
      return (<AddPicture id={succesForm} type="produit"  gabari={selectedGabari.value}/>)
    else
      return (<AddPicture id={match.params.id} type="produit" gabari={selectedGabari.value} />)
  }
  if (succesForm !== "" && end === true) {
    return (<Redirect to="/admin/products" />)
  }
  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }
  return (
    <Container fluid>
      {alert}
      <Row className="justify-content-md-center ml-auto mr-auto">
        <Col lg="12">
          <Form
            action=""
            className="form-horizontal"
            id="Validation"
            method=""
          >
            <Card>
              <Card.Header style={{ textAlign: "center" }}>
                <Card.Title as="h4">
                  {(match.params.id === undefined) ? ("Ajouter un produit") : ("Modifier un produit")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {/* Categorie du produit */}
                <Row style={{ marginBottom: '20px' }}>
                  <Col md="2" />
                  <Col md="3">
                    <Form.Group className="has-success">
                      <label> Categorie du produit <span className="text-danger">*</span></label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="categorie"
                        value={categorie}
                        onChange={(value) => setcategorie(value)}
                        options={AllCategorie}
                        placeholder="Categorie"
                      />
                      {categorie !== undefined ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
                    </Form.Group>
                  </Col>

                  {/* Marque */}
                  <Col md="3">
                    <Form.Group>
                      <label>Marque <span className="text-danger">*</span></label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="attribuea"
                        value={marqueSelected}
                        onChange={(value) => setmarqueSelected(value)}
                        options={AllMarque}
                        placeholder="Categorie"
                      />
                      {marqueSelected !== undefined ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                  {/* gabarit */}
                  <Col md="3">
                    <Form.Group>
                      <label>Gabarit <span className="text-danger">*</span></label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="gabari"
                        value={selectedGabari}
                        onChange={
                          (value) => {
                            setselectedGabari(value)
                            let valueHelp = []
                            for (let index = 0; index < value.value; index++) {
                              valueHelp.push({ titre: "", ismultiple: false, description: "", tag: [] });
                            }
                            setinputListDescription(valueHelp);
                          }
                        }
                        options={StatusGabarit}
                        placeholder="Categorie"
                      />
                      {selectedGabari !== undefined ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être selectionner.
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {/* nom */}
                <Row>
                  <Form.Label column md={{ span: 2, offset: 1 }}>
                    Reference <span className="text-danger">*</span>
                  </Form.Label>
                  <Col md="7">
                    <Form.Group className={referenceState ? "has-success" : "has-error"}>
                      <Form.Control
                        name="reference"
                        type="text"
                        value={reference}
                        onChange={(e) => {
                          setreference(e.target.value);
                          if (minLength(e.target.value, 2)) {
                            setreferenceState(true);
                          } else {
                            setreferenceState(false);
                          }
                        }}
                      ></Form.Control>
                      {referenceState ? null : (
                        <label className="error" style={{ color: "red", textTransform: "none" }}>
                          Ce champ doit être nul.
                        </label>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {/* Description */}
                <Row>
                  <Form.Label column md={{ span: 2, offset: 1 }}>
                    Description
                    </Form.Label>
                  <Col md="9">
                    {inputListDescription.map((x, i) => {
                      return (
                          <Row key={i}>
                            <Col md="12">
                              <Row>
                                <Col md={{ span: 6}} style={{ marginBottom: "15px", paddingLeft: "0px" }}>
                                  <Form.Check >
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    //@ts-ignore.
                                    value={x.ismultiple}
                                    type="checkbox"
                                    onChange={() => {
                                      const list = [...inputListDescription];
                                      list[i]['ismultiple'] = !list[i]['ismultiple'];
                                      setinputListDescription(list);
                                    }
                                    }
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                  <span style={{ fontSize: "large", textTransform: "none", color: "black" }}>
                                   Multiples options ?
                                              </span>
                                </Form.Check.Label>
                              </Form.Check>
                                </Col>
                              </Row>
                           </Col>
                           {!(x.ismultiple)?
                            <Fragment>
                              <Col md="4">
                                <InputGroup className="mb-2">
                                  <InputGroup.Prepend>
                                  <InputGroup.Text>{30 - x.titre.length}</InputGroup.Text>
                                  </InputGroup.Prepend>
                                <Form.Control
                                  name="titre"
                                  placeholder="Sujet"
                                  value={x.titre}
                                  onChange={e => handleInputDescriptionChange(e, i)}
                                />
                              </InputGroup>
                              {x.titre!==""? null : (
                                <label className="error" style={{ color: "red", textTransform: "none" }}>
                                  Ce champ ne peut pas etre vide.
                                </label>
                              )}
                            </Col>
                              <Col md="4" >
                              <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>{30 - x.description.length}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  name="description"
                                  placeholder="Description"
                                  value={x.description}
                                  onChange={e => { 
                                    handleInputDescriptionChange(e, i)
                                  }
                                  }
                                />
                              </InputGroup>
                            </Col>
                            </Fragment>
                          : <Col md="12" style={{ fontSize: "large" }}>
                                <TagsInput
                                  //@ts-ignore.
                                  value={x.tag}
                                  validationRegex={/^.{1,30}$/}
                                  onChange={(value) => {
                                    const list = [...inputListDescription];
                                    list[i]['tag'] = value;
                                    setinputListDescription(list);
                                  }}
                                  tagProps={{
                                    className: "react-tagsinput-tag tag-fill tag-azure",
                                    placeholder: "Titre"
                                  }}
                                  inputProps={
                                    {
                                      className: 'react-tagsinput-input',
                                      placeholder: 'Titre'
                                    }
                                  }
                                />
                              </Col>
                    }
                    
                      </Row>
                );
              })}
                    </Col>
                  </Row>
               </Card.Body>
            <Card.Footer
              className="text-center">
              {renderButtons()}
            </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container >
    );
}
