import Dashboard from "../view/Dashboard/Dashboard";
import TableProspect from "view/Prospect/TableProspect";
import AllPropal from "view/Offre/Propal/AllOffre";
import parse from "html-react-parser";
import AllProspect from "view/Prospect/AllProspect";
import FormProspect from "components/Forms/Prospect/FormProspect";
import AllProduit from "view/Produits/AllProduit";
import FormProduct from "components/Forms/product/FormProduct";
import TableCategorie from "view/Produits/Categorie/TableCategorie";
import TableMarque from "view/Produits/Marque/TableMarque";
import Propal from "view/Offre/Propal/CreateOffre";
import SaSp from "view/Offre/Propal/CreateSASP";
import EmailSend from "components/Email/SendMail";
import RegisterPage from "view/Auth/Register/RegisterPage";
import RegisterPageStep2 from "view/Auth/Register/RegisterPageStep2";
import TableUser from "view/Auth/TableUser";
import AllBDC from "view/Offre/BDC/AllBDC";
import BDC from "view/Offre/BDC/CreateBDC";

const routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },
  {
    collapse: false,
    path: "/prospect",
    layout: "/admin",
    name: "Prospect",
    icon: "nc-icon nc-paper-2",
    component:AllProspect,
  },
  {
    path: "/view_clients",
    layout: "/admin",
    name: "Clients",
    icon: "nc-icon nc-circle-09",
    component: EmailSend,
  },
  {//noView
    collapse: true,
    path: "/prospects",
    name: "prospects",
    state: "prospects",
    icon: "nc-icon nc-paper-2",
    redirect:true,
    views: [
      {
        path: "/add_prospect",
        layout: "/admin",
        name: "Nouveau",
        redirect:true,
        component: FormProspect,

      },
      {
        path: "/edit_prospect/:id",
        layout: "/admin",
        name: "Edit",
        redirect:true,
        component: FormProspect,

      },
      {
        path: "/tableprospect",
        layout: "/admin",
        name: "Afficher",
        redirect:true,
        mini: parse('<i class="nc-icon nc-atom"/>'),
        component: TableProspect,
      },
    ],
  },{
    collapse: false,
    path: "/offre",
    layout: "/admin",
    name: "Offres",
    icon: "fab fa-wpexplorer",
    component:AllPropal,
  },{
    collapse: false,
    path: "/bdc",
    layout: "/admin",
    name: "BDC",
    icon: "fab fa-acquisitions-incorporated",
    component:AllBDC,
  },
  {//noView
    collapse: true,
    path: "/offres",
    name: "Offres",
    state: "offres",
    redirect:true,
    icon: "nc-icon nc-send",
    views: [
      {
        path: "/add_propale/:id/:propal?",
        layout: "/admin",
        name: "Nouvelle Propale",
        mini: "RF",
        component: Propal,
      },
      {
        path: "/add_sasp/:id/:propal?",
        layout: "/admin",
        name: "Nouvelle SA / SP",
        mini: "RF",
        component: SaSp,
      },
      {
        path: "/add_prospect",
        layout: "/admin",
        name: "Nouveau SA/SP",
        mini: "RF",
        component: FormProspect,
      },
      {
        path: "/add_bdc/:id/:offre?",
        layout: "/admin",
        name: "Nouveau BCD",
        mini: "BDC",
        component: BDC,
      },
      {
        path: "/all_prospect",
        layout: "/admin",
        name: "Toutes les offres",
        mini: "EF",
        component: FormProspect,
      }
    ],
  },
  {
    collapse: false,
    path: "/products",
    layout: "/admin",
    state: "accesProduct",
    name: "Produits",
    icon: "nc-icon nc-app",
    component:AllProduit,
  },
  {//noView
    collapse: true,
    path: "/produits",
    name: "Produits",
    state: "accesProduct",
    icon: "nc-icon nc-app",
    redirect:true,
    views: [
      {
        path: "/edit_product/:id",
        layout: "/admin",
        state: "accesProduct",
        name: "Edit",
        redirect:true,
        component: FormProduct,
      },
      {
        path: "/add_product",
        layout: "/admin",
        name: "addProduct",
        state: "accesProduct",
        redirect:true,
        component: FormProduct,
      },
      {
        path: "/all_categorie",
        state: "accesProduct",
        layout: "/admin",
        name: "All Categorie",
        redirect:true,
        component: TableCategorie,
      },
      {
        path: "/all_marque",
        layout: "/admin",
        state: "accesProduct",
        name: "All Marque",
        redirect:true,
        component: TableMarque,
      }
    ],
  },  {
    path: "/createAccount",
    layout: "/admin",
    name: "Creation d'un compte",
    state: "AdminConnected",
    icon: "nc-icon nc-circle-09",
    component: RegisterPage,
  },
  {
    path: "/AllUser",
    layout: "/admin",
    name: "All Users",
    state: "AdminConnected",
    icon: "nc-icon nc-circle-09",
    component: TableUser,
  },
  {//noView
    collapse: true,
    path: "/createAccount",
    name: "Creation d'un compte",
    state: "AdminConnected",
    redirect:true,
    views: [
      {
        path: "/register-step2/:id",
        layout: "/admin",
        name: "Register Step2",
        state: "AdminConnected",
        mini: "RP",
        component: RegisterPageStep2,
      },
    ],
  },

];
export default routes;