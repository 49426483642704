import React from "react";

// react-bootstrap components
import {
  Container,
} from "react-bootstrap";

function Dashboard() {
  return (
    <>
      <Container fluid>
        <h1>Dashboard</h1>
     </Container>
    </>
  );
}

export default Dashboard;
