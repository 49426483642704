import React from "react";
import  {useHistory } from "react-router-dom";
import {
    Button,
    Card,
    Container,
    Row,
    Col
} from "react-bootstrap";
import TableProspect from "./TableProspect";

export default function AllProspect(){
    let history = useHistory();

    const redirect = () => {
        history.push('add_prospect')
      }
    return (

         <Container fluid>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header style={{paddingTop:"20px",paddingLeft:"50px"}}>
                        <Button
                            className="btn-wd btn-outline mr-1"
                            type="button"
                            variant="primary"
                            onClick={redirect}
                        >
                            Ajouter un Prospect
                        </Button>
                        </Card.Header>
                        <Card.Body>
                            <TableProspect/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}