
const StatusProspect=[
  { value: "1", label: "Nouveau" },
  { value: "2", label: "En cours" },
  { value: "3", label: "Refusé" },
];


export default StatusProspect;

  