const ColorBadge=(item:string)=>{

    switch (item) {
        case "Brouillon":
            return 'info'    
        case "Nouveau":
            return 'info'    
        case "Envoyée":
            return 'warning'
        case "En cours":
            return 'warning'
        case 'Décliné':
            return 'danger'
        case 'Refusé':
            return 'danger'
        case 'Acceptée':
            return 'success'
        default:
            return 'secondary'
    }
} 
  
export default ColorBadge;
  
    