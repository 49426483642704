import React from "react";
import { Switch, Route } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
//import AuthFooter from "components/Footers/AuthFooter";

// dinamically create auth routes
import routes_auth from "routes/auth.routes";
import Error500 from "view/error/error500";

export default function Auth() {

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            exact path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;        
      }

    });
  };
  return (
      <div className="wrapper wrapper-full-page">
        {/* Navbar */}
        <AuthNavbar />
        {/* End Navbar */}
        <Switch>
        {/* <Route strict path="/auth" render={(props) => <LoginPage {...props} />} /> */}
          {getRoutes(routes_auth)}
          <Route><Error500 /></Route> 
          </Switch>
      </div>
  );
}
