import ApiService from '../ApiService';
import AuthService from './AuthService';

class MailService {
  

  static GetnewPropalMailer(data) {

    return ApiService.post("mail/GetnewPropal",{id:data},AuthService.getToken())
    .then(res=>{
        if (res.status===200) return res
       return undefined;
    })
    .catch(err=>{throw err})
    }
  static GetnewwbdcMailer(data) {

    return ApiService.post("mail/GetnewBdc",{id:data},AuthService.getToken())
    .then(res=>{
        if (res.status===200) return res
       return undefined;
    })
    .catch(err=>{throw err})
    }

  static SendEmail(data) {
    return ApiService.post("mail/sendmail",data,AuthService.getToken())
    .then(res=>{
        if (res.status===200) return true;
       return false;
    })
    .catch(err=>{throw err})
    }

}


export default MailService;


