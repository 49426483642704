/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactCrop from 'react-image-crop';

import ProspectService from "services/Api/Controllers/ProspectService";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ProductService from "services/Api/Controllers/ProductService";
import 'react-image-crop/dist/ReactCrop.css';

export default function AddPicture({ id,type,gabari}) {
  const [pictureUrl, setpictureUrl] = React.useState(null);

  const [pictureUrlState, setpictureUrlState] = React.useState(true);
  const [show,] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [end, setEnd] = React.useState(false);
  const [succesForm, setSuccesForm] = React.useState(false)
  let history = useHistory();

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(
    (type==="prospect")?{ unit: '%', width: 500, aspect: 16 / 9 }:
    (type==="produit")?
                      (gabari==10)?{ unit: 'px',width: 458,height: 581}:
                      (gabari==6)?{ unit: 'px',width: 556,height: 175}:
    null:null
);
  const [completedCrop, setCompletedCrop] = useState(null);
 
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
  
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = "cropPreview.png";
        anchor.href = URL.createObjectURL(blob);
        Submit(blob)
      // here unnecessary - just for testing if it can be read from local storage
      
        window.URL.revokeObjectURL(previewUrl);
      },
      "image/png",
      1
    );
  }
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const Submit = (blob) => {
    var formData = new FormData();
    formData.append('photo',blob, pictureUrl);
    formData.append('id', id);

    async function data() {
     
      if (type==="prospect"){
       const boolres = await ProspectService.updatePicture(formData);
       setSuccesForm(boolres);
      }
      else if (type==="produit"){
        const boolres = await ProductService.updatePicture(formData);
        setSuccesForm(boolres);
      }
      else{
        const boolres=false;
        setSuccesForm(boolres);
      }
      
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="L'ajout a été effectué avec succès"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Suivant"
        >
        </SweetAlert>
      )
    }
    data();
  }
  const hideAlert = () => {
    setAlert(null);
    setEnd(true);
  }
  const redirect = () => {
    history.replace('prospect')
  }

  if (end === true && succesForm === true) {
    if (type==="prospect"){
      return (<Redirect to="/admin/prospect" />)
    }
    if (type==="produit"){
      return (<Redirect to="/admin/products"/>)
    }
    else{
      return (<Redirect to="/admin/dashboard" />)
    }
  }
  return (
    <>
      {alert}
      {(id !== "") 
      ?(<Modal
          size="lg"
          show={show}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="12" style={{ textAlign: 'center', marginBottom: '30px' }}>
                <h3>Ajouter le logo </h3>
              </Col>
              <Col sm="12">
                <Form.Group className="has-success" style={{ marginBottom: '30px' }}>
                  <Form.File
                    type="file"
                    label={(pictureUrl === null) ? "Selectionner" : pictureUrl.name}
                    data-browse="Recherche"
                    accept=".png, .jpg, .jpeg"
                    onChange={onSelectFile}
                    custom
                  />
                </Form.Group>
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  locked={
                    (type==="prospect")?false:
                    (type==="produit")?true:false}
                />
              </Col>
              <Col md="12">
              <div>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 4 }}>
                <Button 
                onClick={() => { redirect() }} >
                  Annuler
                </Button>

                {pictureUrlState ? null : (
                  <label className="error" style={{ color: "red", textTransform: "none" }}>
                    Un Fichier doit être selectionner.
                  </label>
                )}
                <Button
                  disabled={!completedCrop?.width || !completedCrop?.height}
                  onClick={() => {  generateDownload(previewCanvasRef.current, completedCrop) }}
                  style={{ marginLeft: "5px" }}
                >
                  Telecharger
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>)
      :(<Redirect to="/admin/prospect" />)
      }
    </>
  )
}
