
import { isNumber } from "lib/outilsForms";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, FormGroup, Button, InputGroup, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import OffreService from "services/Api/Controllers/OffreService";
import ProductService from "services/Api/Controllers/ProductService";
import { Redirect } from "react-router-dom";
import BDCService from "services/Api/Controllers/BdcService";


// eslint-disable-next-line react/display-name
const Step3 = React.forwardRef((props, ref) => {

  
  const CurrentIdBDC=(props?.match!==null || props?.match!==undefined)?props?.match?.params?.offre:undefined;
  const CurrentIdPpropal=(props.match!==null || props.match!==undefined)?props.match.params.id:undefined;
  const [inputAllSelected, setinputAllSelected] = useState([{selectMarque: [], selectRef: [],qte:"",designation:"",prixht:""}]);
  const [productErrors, setproductErrors] = useState(true);

  const [dataTableMarque, setDataTableMarque] = useState([]);
  const [dataTableAllProduct, setdataTableAllProduct] = useState([]);
  const [loadingProduit, setloadingProduit] = useState(true);
  const [loadingMarqueTable, setloadingMarqueTable] = useState<Boolean>(true);

  const [alertState, setAlertState] = useState(null);
  const [loadingEdit,setloadingEdit ] = useState(true);
  const [toRedirect, settoRedirect] = useState(false)
  useEffect(() => {
    let isSubscribed = true;

    async function loadDataProduit() {
      const Produit = await ProductService.getallProduct();
      if (isSubscribed) {
        setdataTableAllProduct(Produit);
        setloadingProduit(false);
      }
    }

    async function loadDataMarque() {
      const Produit = await ProductService.getallMarque();
      if (isSubscribed) {
        setDataTableMarque(Produit);
        setloadingMarqueTable(false);
      }
    }

    if (loadingMarqueTable) loadDataMarque()
    if (loadingProduit) loadDataProduit();

    return () => {
      setloadingMarqueTable(true);
      setloadingProduit(true);
      isSubscribed = false;
    }

  }, [])
  useEffect(() => {
    let isSubscribed = true;    
    async function loadEditData() {
      if (loadingEdit) {
        setloadingEdit(false)
        //@ts-ignore.
        const prospectEditVal = await OffreService.getByID(CurrentIdPpropal);
        if(isSubscribed)  {
          setinputAllSelected(prospectEditVal.listTarifaire)
        }
      }
    }
    async function loadDataCurrent() {
      const EditVal = await BDCService.getByID(CurrentIdBDC);
      if(isSubscribed&&loadingEdit)  {
        setinputAllSelected(EditVal?.tarification)
        setloadingEdit(false);
    }
    }

    if (loadingEdit) {
      if(CurrentIdPpropal!==undefined &&CurrentIdBDC===undefined){
        loadEditData().catch(()=>{
          setAlertState(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Vous n'avez pas accès à cette section!"
              onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
              onCancel={() =>{settoRedirect(true);setAlertState(null)}}
              confirmBtnBsStyle="info"
            >
            </SweetAlert>
          );
        });
      }
      if(CurrentIdBDC!==undefined){
        loadDataCurrent().catch(()=>{
          setAlertState(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Vous n'avez pas accès à cette section!"
              onConfirm={() =>{ settoRedirect(true);setAlertState(null)}}
              onCancel={() =>{settoRedirect(true);setAlertState(null)}}
              confirmBtnBsStyle="info"
            >
            </SweetAlert>
          );
        });
      }
    }
    return () => {isSubscribed=false; };
  }, [])

  if(toRedirect) return (<Redirect to="/admin/bdc" />)

  const handleInputDescriptionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputAllSelected];
      list[index][name] = value;
      setinputAllSelected(list);
  };
  const handleInputNumberChange = (e, index) => {
    const { name, value } = e.target;
    if (isNumber(value) || value === "") {
      const list = [...inputAllSelected];
      list[index][name] = value;
      setinputAllSelected(list);
    } else {
      alertEvent("Vous devez saisir un numéro !")
    }
    
  };
  const handleInputSelected = (e, index, name) => {
    setproductErrors(true);
    const list = [...inputAllSelected];
    list[index][name] = e;
    setinputAllSelected(list);
  };
  // gérer l'événement de clic du bouton Supprimer
  const handleRemoveDescriptionClick = index => {
    const list = [...inputAllSelected];
    list.splice(index, 1);
    setinputAllSelected(list);
  };
  // gérer l'événement de clic du bouton Ajouter
  const handleAddDescriptionClick = () => {
    if(inputAllSelected.length<10){
      setinputAllSelected([...inputAllSelected, {  selectMarque: [], selectRef: [],qte:"",designation:"",prixht:""}])
    }else{
      setAlertState(
          <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Vous avez dépassé le nombre maximum d'ajout"
          onConfirm={() => {setAlertState(null)}}
          onCancel={() => setAlertState(null)}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          confirmBtnText="J'ai compris!"
          ></SweetAlert>
     )
    }  
    };
  const isValidated = () => {
    const inputAllSelectedValide = (inputAllSelected.length === 1) ?
      (inputAllSelected[0].selectMarque.length !== 0
        && inputAllSelected[0].selectRef.length !== 0
        && inputAllSelected[0].qte !== ""
        && inputAllSelected[0].designation !== ""
        && inputAllSelected[0].prixht !== ""
      ) : null;

    if (inputAllSelected.length === 1) {
      if (!inputAllSelectedValide) alertEvent("Vous avez sélectionné un produit que vous n'avez pas completé");
    }

    var IsValidate = true;
    inputAllSelected.forEach(item => (
      (item.selectMarque.length !== 0 && item.selectRef.length !== 0&& item.qte !== ""&& item.designation !== ""&& item.prixht !== "") ? null : IsValidate = false
    )
    );

    var isEmpty = true;
    if (!IsValidate) {
      if (inputAllSelected.length > 1) {
        inputAllSelected.forEach((item, index) => (
          (index > 0) ?
            (item.selectMarque.length === 0 && item.selectRef.length === 0&& item.qte === ""&& item.designation === ""&& item.prixht === "") ? isEmpty = false : null
            : null
        ));
        if (!isEmpty) alertEvent("Vous avez sélectionné un produit que vous n'avez pas completé");
        else alertEvent("Vous avez sélectionné un produit que vous n'avez pas terminé");
      }
    }

    if (inputAllSelected.length === 1) {
      setproductErrors(inputAllSelectedValide && IsValidate)
      return inputAllSelectedValide && IsValidate;
    }

    setproductErrors(IsValidate);

    return IsValidate;
  };

  const alertEvent = (title) => {
    setAlertState(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => { setAlertState(null) }}
        onCancel={() => setAlertState(null)}
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="danger"
        confirmBtnText="J'ai compris!"
      >
      </SweetAlert>
    )
  }


React.useImperativeHandle(ref, () => ({
  isValidated: () => {
    return isValidated();
  },
  state:inputAllSelected
}));
return (
  <>
    {alertState}
    <div className="wizard-step">
      <p className="text-center">
        <b>Creation de la proposition tarifaire</b>
        <br />
      </p>
      <FormGroup>
        {/* Description */}
        {inputAllSelected?.map((x, i) => {
          return (
            <Row key={i}>
             <Col md={{span:2,offset:1}}>
                <label>Marque <span className="text-danger">*</span></label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectMarque"
                  value={x.selectMarque}
                  onChange={e => handleInputSelected(e, i, "selectMarque")}
                  options={dataTableMarque}
                  placeholder="Marque"
                />
                {(productErrors || x.selectMarque.length !== 0) ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col md="2">
                <label>Reference <span className="text-danger">*</span></label>
                <Select
                  isDisabled={!(x.selectMarque.length !== 0)}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="selectRef"
                  value={x.selectRef}
                  onChange={e => handleInputSelected(e, i, "selectRef")}
                  options={
                    dataTableAllProduct
                      .filter(acc => (acc['marque'] === x.selectMarque.value))
                      .map(response => new Object({ value: response._id, label: response.reference }))
                  }
                  placeholder="Reference"
                />
                {(productErrors || x.selectRef.length !== 0) ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col md="2">
                <label>Désignation<span className="text-danger">*</span></label>
                  <Form.Control
                        name="designation"
                        placeholder="..."
                        value={x.designation}
                        onChange={e => handleInputDescriptionChange(e, i)}
                  />
                {(productErrors || x.designation!== "") ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>     
              <Col md="2">
                <label>Qté <span className="text-danger">*</span></label>
                  <Form.Control
                        name="qte"
                        placeholder="..."
                        value={x.qte}
                        onChange={e => handleInputNumberChange(e, i)}
                  />
                {(productErrors || x.qte!== "") ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>         
              <Col md="2">
                <label>Prix Ht <span className="text-danger">*</span></label>
                <InputGroup className="mb-2">
                 <InputGroup.Prepend>
                  <InputGroup.Text>€</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                        name="prixht"
                        placeholder="..."
                        value={x.prixht}
                        onChange={e => handleInputNumberChange(e, i)}
                  />
                </InputGroup>
                {(productErrors || x.prixht!== "") ? null : (
                  <label className="error " style={{ color: "red", textTransform: "none" }}>
                    Ne peut pas etre vide.
                  </label>
                )}
              </Col>
              <Col className="mb-2 text-right" md="12" >
                <div style={{ marginTop: '20px' }}>
                  {inputAllSelected.length !== 1
                    &&
                    <Button
                      variant="warning"
                      onClick={() => handleRemoveDescriptionClick(i)}
                    >
                      <span style={{ fontSize: "large" }}>-</span>
                    </Button>
                  }
                  {inputAllSelected.length - 1 === i
                    &&
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="info"
                      onClick={handleAddDescriptionClick}
                    >
                      <span style={{ fontSize: "large" }}>+</span>
                    </Button>
                  }
                </div>
              </Col>
            </Row>
          );
        })}
      </FormGroup>
    </div>
  </>
);


});

export default Step3;
